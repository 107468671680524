/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@include mat.core();

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

$custom-theme: mat.define-light-theme((
color: (
primary: mat.define-palette(mat.$indigo-palette),
accent: mat.define-palette(mat.$pink-palette),
),
));

@include mat.all-component-themes($custom-theme);
